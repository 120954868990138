import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Loader_img from "../../images/Tek-Spinner.gif";

function Loader(props) {
    return (
        <div className="loader_banner text-center vh-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="m-auto">
                        <div className="loading">
                            <img src={Loader_img}
                                 alt="loader" className="img-fluid"/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Loader;